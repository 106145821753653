import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Register ScrollTrigger and ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const menuItems = [
  { id: 'intro', label: 'About', icon: 'ℹ' },
  { id: 'skills', label: 'Skills', icon: '✦' },
  { id: 'projects', label: 'Projects', icon: '✎' },
  { id: 'contact', label: 'Contact', icon: '✉' }
];

export default function NavigationMenu() {
  const [activeSection, setActiveSection] = useState('intro');
  const [isScrolled, setIsScrolled] = useState(false);
  const navRef = useRef(null);
  const menuItemsRef = useRef([]);

  useEffect(() => {
    // Initial nav animation
    gsap.fromTo(navRef.current,
      {
        opacity: 0,
        y: -100
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: "power3.out"
      }
    );

    // Setup scroll animation for nav background
    const updateNavBackground = () => {
      const scrolled = window.scrollY > 50;
      setIsScrolled(scrolled);
      
      gsap.to(navRef.current, {
        backgroundColor: scrolled ? 'rgba(3, 7, 18, 0.8)' : 'rgba(3, 7, 18, 0)',
        backdropFilter: scrolled ? 'blur(12px)' : 'blur(0px)',
        duration: 0.3,
        ease: "power2.out"
      });
    };

    window.addEventListener('scroll', updateNavBackground);
    return () => window.removeEventListener('scroll', updateNavBackground);
  }, []);

  useEffect(() => {
    // Setup hover animations for menu items
    menuItemsRef.current.forEach(item => {
      if (!item) return;

      item.addEventListener('mouseenter', () => {
        gsap.to(item, {
          scale: 1.1,
          duration: 0.3,
          ease: "power2.out"
        });
      });

      item.addEventListener('mouseleave', () => {
        gsap.to(item, {
          scale: 1,
          duration: 0.3,
          ease: "power2.out"
        });
      });

      item.addEventListener('mousedown', () => {
        gsap.to(item, {
          scale: 0.95,
          duration: 0.1,
          ease: "power2.out"
        });
      });

      item.addEventListener('mouseup', () => {
        gsap.to(item, {
          scale: 1.1,
          duration: 0.1,
          ease: "power2.out"
        });
      });
    });
  }, []);

  useEffect(() => {
    let observer;
    let mutationObserver;
    
    const setupObserver = () => {
      if (observer) {
        observer.disconnect();
      }

      observer = new IntersectionObserver(
        (entries) => {
          let maxVisibility = 0;
          let mostVisibleSection = null;

          entries.forEach((entry) => {
            if (entry.intersectionRatio > maxVisibility) {
              maxVisibility = entry.intersectionRatio;
              mostVisibleSection = entry.target.id.replace('-section', '');
            }
          });

          if (mostVisibleSection && maxVisibility > 0.2) {
            setActiveSection(mostVisibleSection);
          }
        },
        { 
          threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
          rootMargin: '-10% 0px -40% 0px'
        }
      );

      const sections = document.querySelectorAll('section[id]');
      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
    };

    mutationObserver = new MutationObserver((mutations) => {
      let shouldResetup = false;
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
          shouldResetup = true;
        }
      });
      if (shouldResetup) {
        setupObserver();
      }
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true
    });

    setupObserver();

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(`${id}-section`);
    if (element) {
      gsap.to(window, {
        duration: 0.2,
        scrollTo: {
          y: element,
          offsetY: 0
        },
        ease: "power3.inOut"
      });
    }
  };

  return (
    <>
      {/* Desktop Navigation - Top Bar */}
      <nav
        ref={navRef}
        className="fixed top-0 left-0 right-0 z-50 hidden md:block opacity-0"
        style={{
          backgroundColor: isScrolled ? 'rgba(3, 7, 18, 0.8)' : 'transparent',
          backdropFilter: isScrolled ? 'blur(12px)' : 'none'
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-2">
          <ul className="flex justify-center items-center gap-2">
            {menuItems.map(({ id, label, icon }, index) => (
              <li
                key={id}
                ref={el => menuItemsRef.current[index] = el}
                className="relative group"
              >
                <button
                  onClick={() => scrollToSection(id)}
                  className={`px-3 py-2 rounded-lg flex items-center gap-2 transition-all duration-300
                    ${activeSection === id 
                      ? 'text-[#818cf8] font-semibold' 
                      : 'hover:bg-[#818cf820] text-[#94a3b8]'
                    }`}
                  aria-label={label}
                >
                  <span className={`text-sm ${activeSection === id ? 'text-[#818cf8]' : ''}`}>{icon}</span>
                  <span className={`text-sm font-medium ${activeSection === id ? 'text-[#818cf8]' : ''}`}>{label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}
