// App.jsx
import React, { useEffect, lazy, Suspense, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ParticlesBackground from './components/ParticlesBackground.jsx';
import NavigationMenu from './components/NavigationMenu.jsx';
import './App.css';

const IntroSection = lazy(() => import('./components/IntroSection.jsx'));
const SkillsSection = lazy(() => import('./components/SkillsSection.jsx'));
const ProjectsSection = lazy(() => import('./components/ProjectsSection.jsx'));
const ContactSection = lazy(() => import('./components/ContactSection.jsx'));

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
    <div className="h-32 w-32 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin" />
  </div>
);

export default function App() {
  const progressBarRef = useRef(null);
  const [currentSection, setCurrentSection] = useState(0);
  const isScrolling = useRef(false);
  
  useEffect(() => {
    // Register GSAP plugins
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    
    // Prevent default scroll
    document.body.style.overflow = 'hidden';
    
    const sections = ['intro-section', 'skills-section', 'projects-section', 'contact-section'];
    
    const scrollToSection = (index) => {
      if (isScrolling.current) return;
      if (index < 0 || index >= sections.length) return;
      
      isScrolling.current = true;
      setCurrentSection(index);
      
      const targetSection = document.getElementById(sections[index]);
      if (!targetSection) return;

      // Trigger enter animations for the target section
      const event = new CustomEvent('sectionEnter', { detail: { sectionId: sections[index] } });
      window.dispatchEvent(event);
      
      gsap.to(window, {
        duration: 0.6,
        scrollTo: { y: targetSection, offsetY: 0 },
        ease: "power2.out",
        onComplete: () => {
          setTimeout(() => {
            isScrolling.current = false;
          }, 50);
        }
      });
    };

    const handleWheel = (e) => {
      e.preventDefault();
      if (isScrolling.current) return;
      
      const direction = e.deltaY > 0 ? 1 : -1;
      scrollToSection(currentSection + direction);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        if (isScrolling.current) return;
        
        const direction = e.key === 'ArrowDown' ? 1 : -1;
        scrollToSection(currentSection + direction);
      }
    };

    // Add event listeners
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('keydown', handleKeyDown);

    // Initialize progress bar
    gsap.to(progressBarRef.current, {
      scaleX: 1,
      ease: 'none',
      transformOrigin: 'left center',
      scrollTrigger: {
        trigger: document.body,
        start: 'top top',
        end: 'bottom bottom',
        scrub: 0.1
      }
    });

    // Cleanup
    return () => {
      document.body.style.overflow = '';
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, [currentSection]);

  return (
    <div className="relative min-h-screen bg-gray-900 text-white">
      <div
        ref={progressBarRef}
        className="fixed top-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 z-50"
      />
      <ParticlesBackground />
      <NavigationMenu currentSection={currentSection} />
      <Suspense fallback={<LoadingSpinner />}>
        <section id="intro-section" className="min-h-screen flex items-center"><IntroSection /></section>
        <section id="skills-section" className="min-h-screen flex items-center"><SkillsSection /></section>
        <section id="projects-section" className="min-h-screen flex items-center"><ProjectsSection /></section>
        <section id="contact-section" className="min-h-screen flex items-center"><ContactSection /></section>
      </Suspense>
    </div>
  );
}
